'use strict';

angular.module('enervexSalesappApp').factory('VentbomProject', function ($resource) {
	return $resource('/api/accounts/:accountId/ventbom-projects/:id/:sync', {
		id: '@id',
		sync: '@sync'
	},
	{
		update: {
			method: 'PUT'
		},
		syncOne: {
			method: 'POST',
			params: {
				sync: 'sync',
				accountId:'@accountId',
				id:'@id'
			}
		},
	});
});
